import React from 'react';
import Layout from '../components/layout'
import Choose from '../components/Choose/Choose';
import Labs from '../components/Labs/Labs';
import RegMed from '../components/Forms/registermed';
import Work from '../components/Work/Work';
import MapCom from '../components/Map/MapCom';
import signupback from '../images/hpHomeBG.jpg'


const MedicalLabs = () => {
    return (
        <Layout>
            {/* <MapCom /> */}
            {/* <Labs /> */}
            <div class="inner-page">
            <div class="slider-item overlay bg-overly homBg" style={{backgroundImage:`url(${signupback})`}} >
                    <div class="container">
                    <div class="flex flex-row slider-text items-center justify-center" style={{height:450}}>
                    <h1 class="self-center text-white text-5xl">HEALTH CENTER SERVICES</h1>
                </div>
                        
                    </div>
                </div>
            </div>
            <Work />
            <RegMed />
            <Choose />
            
        </Layout>
    );
}

export default MedicalLabs;