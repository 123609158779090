import React from 'react';
import { BsPhone } from 'react-icons/bs';
import { FaUserClock, FaRegHospital } from 'react-icons/fa'
import { ImLab } from 'react-icons/im'
import { GiMedicinePills } from 'react-icons/gi'
import { FaUserNurse } from 'react-icons/fa'

import "./Work.css";

const Work = () => {
    return (
        <div class="pt-8 px-8">
            <h2 style={{textAlign: 'center'}} class="text-3xl text-indigo-800">Our circle of care</h2>
            <div className="work-info" >
                <div className="working-info" >
                    
                    <div>
                    <span className="p-2 flex items-center justify-center mb-2 flex-row bg-purple-200 text-lg text-purple-700 rounded-full ">
                        <ImLab class="text-2xl"/> &nbsp; Medical Labs
                    </span>
                        <p class="text-justify text-gray-700">
                            Join our growing list of verified medical labs / diagnostic centers accross the country
                            and grow your business through our referrals for tests and medical kits. We assure exponential 
                            growth in your patronage. Payments are remitted directly via our platform or however medium agreed or preferred by you.
                           
                        </p>
                    </div>
                </div>
                <div className="working-info" >
                    <div>
                    <span className="p-2 flex items-center justify-center mb-2 flex-row bg-blue-200 text-lg text-blue-700 rounded-full ">
                        <GiMedicinePills class="text-2xl"/> &nbsp; Pharmacies
                    </span>
                        
                        <p class="text-justify text-gray-700">
                            Register as a pharmacy and increase your patronage via our referrals for prescriptions
                            as well as medication delivery for our patients within and around your immediate locality.
                            Payments are remitted directly via our platform or however medium agreed or preferred by you.
                        </p>
                    </div>
                </div>
                <div className="working-info" >
                    <div>
                    <span className="p-2 flex items-center justify-center mb-2 flex-row bg-red-200 text-lg text-red-700 rounded-full ">
                        <FaUserNurse class="text-2xl"/> &nbsp; Dispatch Lab Scientists
                    </span>
                        <p class="text-justify text-gray-700">
                            Earn extra income as an individual dispatch scientist or as an attachment from your 
                            medical lab for home serviced sample collections and result delivery via the healthpeer
                            platform. Get paid per complete rounds and run as much rounds as you wish at your convenience.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Work;