import React from 'react';
import styled from 'styled-components';
import pic from '../../images/scope4.png';
import "./Choose.css"

const Cards = styled.div`
  ${tw`w-48 h-48 shadow-lg p-4 rounded-lg`}
`

const Content = styled.div`
  ${tw`flex flex-row`}
`

const Header = styled.h1`
  ${tw`text-base text-grey-lighter`}
`

const Text = styled.p`
  ${tw`text-justify leading-wide text-grey-dark`}
`

const Choose = () => {
    return (
      <div class="pt-8 px-8">
        <h2 style={{textAlign: 'center'}} class="text-3xl text-indigo-800">Why Choose Us</h2>

        <p class="px-8 choose-par">
          Our transformative healthcare services cut across the whole circle of care.
        </p>
        <div className="choose">
          <div className="choose-cards" >
            <Cards className="choose-cards1">
              <h5>Expert Professionals</h5>
              <Text>
                Our professionals are highly verified and we have expertise around all medical cores with
                high emphasis on quality health delivery.
              </Text>
            </Cards>
            <Cards className="choose-cards1">
              <h5>Extra Income</h5>
              <Text>
                We give you an avenue to make extra income individually or as a business whilst delivery 
                quality medical services to your customers.
              </Text>
            </Cards>
            <Cards className="choose-cards1">
              <h5>24 Hour Service</h5>
              <Text>
                Our services are not limited but offer flexible timing to accomodate customers around the clock.
              </Text>
            </Cards>
            <Cards className="choose-cards1">
              <h5>Increased Patronage</h5>
              <Text>
                We guarantee your business a steady growth in your customer base via our referral system based on your 
                location.
              </Text>
            </Cards>
          </div>
          <div className="choose-img" >
            <img src={pic} alt="" width={600} />
          </div>
        </div>
      </div>
    )
}

export default Choose;