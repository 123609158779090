import React,{useState, useEffect} from 'react';
import "./Labs.css"

const Labs = () => {

    const labs = [
      {
        name: 'Medical Lab 1',
        location: 'Kaduna',
      },
      {
        name: 'Sample Lab 1',
        location: 'Abuja',
      },
      {
        name: 'Another Lab 1',
        location: 'Lagos',
      },
      {
        name: 'Medical Lab 4',
        location: 'Kaduna',
      },
      {
        name: 'Permanent Medical Lab 1',
        location: 'Abuja',
      },
      {
        name: 'Some Lab 1',
        location: 'Lagos',
      },
      {
        name: 'Some Testing Things',
        location: 'Port Harcout'
      }
    ];

    const [showLocations, setShowLocations] = useState([]); 
    const [selectLocation, setSelectLocation] = useState("All");

    useEffect(() => {
        setLocations();
        setShowLocations(labs)
    }, [])

    const setLocations = () => {
        let l = []
        for(let i = 0; i < labs.length; i++) {
            let arr = l;
            let found = false;
            arr.forEach(ar => {
                if(labs[i].location === ar){
                    found = true;
                }
            })

            !found && l.push(labs[i].location);
        }
        return (
          <select
            onChange={e => {
              setSelectLocation(e.target.value)
            }}
            name="locations" className="labselect"
          >
            <option value="All" selected>
              All
            </option>
            {l.map((lab, i) => (
              <option key={i} value={lab}>
                {' '}
                {lab}{' '}
              </option>
            ))}
          </select>
        )
    }

    return (
      <div class="ml-20" className="body-labs">
        {/* <div className="lab-header">
          <div>
            <p>Lab</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>Location</p>
            {setLocations()}
          </div>
        </div> */}

        {/* {
                selectLocation === "All" ? 
                    labs.map((lab, i) => (
                        <div className="labs" key={i}>
                            <p> {lab.name} </p>
                            <p className="location" > {lab.location} </p>
                          </div>
                    ))
                :
                showLocations.map((lab, i) => {
                    if(lab.location === selectLocation) {
                        return (
                          <div className="labs" key={i}>
                            <p> {lab.name} </p>
                            <p className="location"> {lab.location} </p>
                          </div>
                        )
                    }        
                })
            } */}

        <div className="labs">
          <div>
            <p> Type </p>
            <select name="type" className="labselect" >
              <option value="test1">Type 1</option>
              <option value="test2">Type 2</option>
              <option value="test3">Type 3</option>
            </select>
          </div>
          <div className="name" >
            <p> Name </p>
            <select name="name" className="labselect">
              <option value="name1">Name 1</option>
              <option value="name2">Name 2</option>
            </select>
          </div>
          <div>
            <p className="location"> Location </p>
            {setLocations()}
          </div>
          <div>
            <button>Search</button>
          </div>
        </div>
      </div>
    )
}

export default Labs;